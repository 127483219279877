const isCustomerServiceOff = () => {
  const userTime = new Date()
  const weekndDays = [0, 6]

  const isWeeknd = weekndDays.includes(userTime.getDay())
  const hoursInBRT = userTime.getUTCHours() - 3

  const isNightTime =
    (hoursInBRT > 18 && hoursInBRT < 24) || (hoursInBRT > 0 && hoursInBRT < 8)

  return isWeeknd || isNightTime
}

export const RED_TESTING_CONFIG = {
  experiments: [
    {
      id: 'form_v2',
      variants: [
        {
          weight: isCustomerServiceOff() ? 1.0 : v2_percentage // eslint-disable-line no-undef
        }
      ]
    },
    {
      id: 'paywall',
      variants: [
        {
          weight: 0.5
        }
      ]
    }
  ]
}
